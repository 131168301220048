import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../../components/Layout';
import FeatureRoll from '../../components/FeatureRoll';

import Hero from '../../components/Hero';
import CTA from '../../components/CTA';

import { Section } from '../../components/bulma';

import SEO from '../../components/SEO/SEO';

export default function BlogIndexPage() {
	const data = useStaticQuery(FEATURES_QUERY);
	const { edges: posts } = data.allMarkdownRemark;

	const { node: { frontmatter: { title, featuredImage }, excerpt, fields: { slug } } } = posts[0];

	return (
		<Layout>
			<SEO
				title="Complete Payment Solution for Business"
				description="Learn about Payment.Ninja's cutting-edge platform features."
				// image={image.childImageSharp.sizes.src}
				pathname="/news"
				article
			/>
			<br />
			<Hero
				title="Features"
				subtitle="Payment.Ninja is a modern, feature-rich merchant platform"
				// image={featuredImage}
			/>
			<Section>
				<FeatureRoll />
			</Section>
			<CTA />
		</Layout>
	);
}

const FEATURES_QUERY = graphql`
	query Features {
		allMarkdownRemark(
			sort: { order: DESC, fields: [frontmatter___date] }
			filter: { frontmatter: { templateKey: { eq: "feature-page" } } }
		) {
			edges {
				node {
					id
					fields {
						slug
					}
					frontmatter {
						title
						templateKey
						date(formatString: "MMMM DD, YYYY")
						featuredpost
						featuredimage {
							childImageSharp {
								fluid(maxWidth: 120, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}
		}
	}
`;
